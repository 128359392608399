import {mapValues} from 'lodash-es'
import pageData from '../../content/settings/notFound.json'
import useLang from '../hooks/useLang'
import AppShell from '../components/containers/AppShell'
import SubpageHero from '../components/molecules/SubpageHero'
import Navbar from '../components/containers/Navbar'
import SubpageDescription from '../components/molecules/SubpageHero/SubpageDescription'
import SubpageSubDescription from '../components/molecules/SubpageHero/SubpageSubDescription'


const NotFoundPage = () => {
  const langToSlug = mapValues(pageData, 'slug')
  const {lang} = useLang('cs', langToSlug)
  const {cs} = pageData

  return (
    <AppShell title={cs.menuText} lang={lang} langToSlug={langToSlug}>
      <Navbar title={cs.returnMenuText} titleLink="/" lang={lang} langToSlug={langToSlug} />
      <SubpageHero
          title={cs.hero.title}
          fadedSideText={cs.hero.sideTextNumber}
          sideText={cs.hero.sideText}
      >
        <SubpageDescription>
          {cs.hero.description}
        </SubpageDescription>
        <SubpageSubDescription>
          {cs.hero.subDescription}
        </SubpageSubDescription>
      </SubpageHero>
    </AppShell>
  )
}

export default NotFoundPage
